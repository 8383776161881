import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { MatchScoreProps } from '.';
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      fontWeight: 'bold',
      color: theme.custom.content[2],

      '& > span': {
        display: 'inline-block',
        minWidth: '2rem',
        fontWeight: '500',
        textAlign: 'right',
        '&:first-child': {
          fontWeight: '500',
        },

        '&:last-child': {
          marginRight: 0,
          color: theme.custom.status.green,
          fontWeight: '700 !important',
        },
      },
    },
  };
});

export const TennisScore: React.FCC<MatchScoreProps> = (props) => {
  const { classes, cx } = useStyles();
  const { data, team } = props;
  const { sportEventStatus: _event } = data;
  const last = _.last(_event?.period_scores?.period_score);

  const isA = team === 'a';

  const pointScore = _.get(
    _event,
    [isA ? '_home_gamescore' : '_away_gamescore'],
    '0',
  );

  return (
    <div className={classes.root}>
      <span>{_.get(_event, [isA ? '_home_score' : '_away_score'], '0')}</span>
      <span>{_.get(last, [isA ? '_home_score' : '_away_score'], '0')}</span>
      <span className={classes.root}>
        {pointScore === '50' ? 'AD' : pointScore}
      </span>
    </div>
  );
};
