import { request } from 'src/utils/request';
import { BaseResponse } from 'src/utils/types/response';

export interface GetFixtureCount {
  numberOfFixtureUp: number;
  numberOfFixtureLive: number;
  numberOfOutright: number;
  numberOfFixtures: number;
}

export const getFixtureCount = async (slug: string) => {
  const fields = 'numberOfFixtureUp,numberOfFixtureLive,numberOfOutright';
  const { data } = await request.get<BaseResponse<GetFixtureCount>>(
    'sport/detail',
    { params: { slug, fields } },
  );

  const convertedData = {
    ...data.data,
    numberOfFixtures:
      data.data.numberOfFixtureUp + data.data.numberOfFixtureLive,
  } satisfies GetFixtureCount;

  return convertedData;
};
