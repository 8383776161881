import { Plural } from '@lingui/macro';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MyLink } from 'src/components/core/mine/my-link';
import { useRoom } from 'src/hooks/socket/use-room';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { useSportFilter } from 'src/hooks/use-sport-filter';
import { BrMarketStatus } from 'src/utils/constants/common-constant';
import { Paths } from 'src/utils/constants/path';
import { SocketEvent } from 'src/utils/constants/socket-event';
import {
  mergeEventWithOddsChange,
  updateEventWithBetSettlement,
} from 'src/utils/helpers/async-helpers';
import { generateKey, hideTooltip } from 'src/utils/helpers/fixture';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { BaseEvent } from 'src/utils/types/event';
import { BetGameButton } from '../../../bet-game';
import { FixtureInfo } from '../fixture-info';
import { MarketName } from '../market-name';
import { StreamFrameV2 } from '../stream-frame';
import { useStyles } from './styles';
import ArrowRight from 'src/assets/icons/arrow-right.svg';

interface FixtureRowV2Props {
  data: BaseEvent;
}

export const FixtureRowV2: React.FCC<FixtureRowV2Props> = ({ data: _data }) => {
  const { classes } = useStyles();

  const [stateData, setStateData] = useState<BaseEvent>(_data);

  const marketOutcomes = _.chain(_data.mainPopularOutcomes)
    .get('marketOutcomes', [])
    .value();
  const filter = useSportFilter();

  const marketName = _.first(marketOutcomes)?.marketName;
  const filterMarketName =
    filter?.selectedMarket?.marketCustomName ||
    filter?.selectedMarket?.marketName;

  useEffect(() => {
    setStateData(_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketName, filterMarketName]);

  const data: BaseEvent = { ..._data, ...stateData };
  const { sportId, eventId, _eventSlug } = data;

  const sportObj = useSportFinder({ sportId });

  useRoom(SocketEvent.ODD_CHANGE_V5, eventId, async ({ message }) => {
    setStateData((prev) => mergeEventWithOddsChange(prev, message));
  });

  useRoom(SocketEvent.FIXTURE_CHANGE_V5, eventId, (msg) => {
    const { fixture_refined } = msg;
    setStateData((prev) => ({ ...prev, ...fixture_refined }));
  });

  useRoom(SocketEvent.BET_STOP_V5, eventId, (msg) => {
    const marketStatus =
      msg.message?._market_status || BrMarketStatus.SUSPENDED;

    setStateData((prev) => ({
      ...prev,
      mainPopularOutcomes: {
        ...prev.mainPopularOutcomes,
        marketOutcomes: _.map(prev?.mainPopularOutcomes?.marketOutcomes, (o) =>
          o ? { ...o, marketStatus } : o,
        ),
      },
    }));
  });

  useRoom(SocketEvent.BET_SETTLEMENT, eventId, async (msg) => {
    setStateData((prev) => updateEventWithBetSettlement(prev, msg.message));
  });

  const [open, setOpen] = useState(false);

  const outcomes = _.get(data, ['mainPopularOutcomes', 'marketOutcomes']);

  return (
    <div className={classes.root}>
      <Grid container spacing={{ xs: 1, sm: 1, md: 2 }} alignItems="center">
        <Grid item md={5.6} sm={12} xs={12}>
          <FixtureInfo
            data={data}
            onWatchNow={(e) => {
              e.preventDefault();
              setOpen(true);
              return false;
            }}
          />
        </Grid>

        <Grid item md={false} sm={12} xs={12} className={'mobile'}>
          <MarketName data={data} />
        </Grid>
        {_.map(outcomes, (betGame, index, arr) => {
          const hcpOrTotal = hideTooltip(eventId, betGame?.marketSpecifiers);

          const length = arr.length;

          const spans = {
            md: 52 / length / 10,
            ...(hcpOrTotal
              ? { xs: 12, sm: 12 }
              : { xs: 12 / length, sm: 12 / length }),
          };

          return (
            <Grid key={generateKey(betGame) ?? betGame?._id} item {...spans}>
              <BetGameButton data={betGame} fixture={data} />
            </Grid>
          );
        })}

        <Grid
          item
          md={1.2}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MyLink
            href={
              sportObj
                ? generatePath(Paths.DetailFixture, {
                    sportType: sportObj.__sportType,
                    slug: sportObj.slug,
                    eventId: _eventSlug ?? eventId,
                  })
                : '#'
            }
            color="inherit">
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              pt={{ xs: 1, md: 0 }}>
              <Plural
                id="sportsbook.outcome.more_markets"
                value={data.numberOfMarket ?? 0}
                one="+# market"
                other="+# markets"
              />
              <ArrowRight />
            </Stack>
          </MyLink>
        </Grid>

        <StreamFrameV2 data={data} open={open} onClose={() => setOpen(false)} />
      </Grid>
    </div>
  );
};
