import { useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { favGamesAtom } from 'src/store/casino';
import { favTournamentsAtom } from 'src/store/sport';
import { isOddin } from 'src/utils/helpers/fixture';
import { CasinoGame } from 'src/utils/types/casino';

export const useIsFavTour = (tournamentId?: string) => {
  const tournaments = useAtomValue(favTournamentsAtom);

  if (!tournamentId) return false;

  const items =
    tournamentId && isOddin(tournamentId)
      ? tournaments.eSport
      : tournaments.sport;

  return !!_.find(items, { tournamentId });
};

export const useIsFavGame = (game?: CasinoGame) => {
  const id = game?._id;
  const [favs, toggleFavCasinoGame] = useAtom(favGamesAtom);
  const fav = _.find(favs, { _id: id }) !== undefined;

  const toggleFav = () => {
    if (!game) return;
    toggleFavCasinoGame(game);
  };

  return { fav, toggleFav };
};
