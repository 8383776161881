import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold',
      margin: theme.spacing(1.5, 0),
    },

    iframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      border: 'none',
    },
  };
});
