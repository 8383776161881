import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { MatchScoreProps } from '.';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      fontWeight: 'bold',
      color: theme.custom.status.green,
      marginLeft: theme.spacing(3),
    },
  };
});

export const SoccerScore: React.FCC<MatchScoreProps> = (props) => {
  const { classes, cx } = useStyles();

  return (
    <span className={classes.root}>
      {_.get(
        props?.data?.sportEventStatus,
        [props.team === 'a' ? '_home_score' : '_away_score'],
        '0',
      )}
    </span>
  );
};
