import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { fetchPopularMarkets } from 'src/api/sportbook-v2/sports';
import { GetFixtureCount, getFixtureCount } from 'src/api/sports';
import { MyLinkProps } from 'src/components/core/mine/my-link';
import { Paths } from 'src/utils/constants/path';
import {
  fetchPopularMarketsKey,
  getFixtureCountKey,
} from 'src/utils/helpers/swr';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { FixtureStatus } from 'src/utils/types/event';
import { IMarketFilter, MySport } from 'src/utils/types/sport';
import useSWRImmutable from 'swr/immutable';
import { useCurrentSport } from './use-current-sport';
import { useLocale, useMyPathname } from './use-locale';
import { useQuery } from './use-query';

interface StatusTab {
  name: React.ReactNode;
  key: string; // will be displayed on url
  val: number | string; // will be passed to API
}

export interface ISportFilter {
  isLoading: boolean;
  currentSport: MySport | null;
  statusOptions: StatusTab[] | undefined;
  selectedStatus?: FixtureStatus;
  marketOptions: IMarketFilter[] | undefined;
  selectedMarket?: IMarketFilter;

  getStatusToProps: (status: string) => MyLinkProps['href'];
  getFilterToProps: (market: string) => MyLinkProps['href'];
}

const QUERY_FILTER_KEY = 'market';

const getStatusOpts = (value: GetFixtureCount | undefined) => {
  return _.chain([
    (value?.numberOfFixtureUp || 0) > 0 && {
      name: <Trans id="sportsbook.upcoming">Upcoming</Trans>,
      key: 'upcoming',
      val: FixtureStatus.NOT_STARTED,
    },
    (value?.numberOfFixtureLive || 0) > 0 && {
      name: <Trans id="sportsbook.live_now">Live sports</Trans>,
      key: 'live-now',
      val: FixtureStatus.LIVE,
    },
    (value?.numberOfOutright || 0) > 0 && {
      name: <Trans id="sportsbook.futures">Outrights</Trans>,
      key: 'futures',
      val: FixtureStatus.FUTURE,
    },
    (value?.numberOfFixtures || 0) > 0 && {
      name: <Trans id="sportsbook.leagues">Leagues</Trans>,
      key: 'league',
      val: FixtureStatus.LEAGUE,
    },
  ]).compact();
};

const SportFilterCtx = React.createContext<ISportFilter | null>(null);

export const useSportFilter = () => useContext(SportFilterCtx) ?? undefined;

export const SportFilterProvider: React.FCC = ({ children }) => {
  const currentSport = useCurrentSport();

  const counts = useSWRImmutable(
    currentSport ? getFixtureCountKey(currentSport.slug) : null,
    ([, slug]) => {
      return getFixtureCount(slug);
    },
  );

  const queries = useQuery(QUERY_FILTER_KEY);
  const marketName = queries[QUERY_FILTER_KEY];
  const { pathname } = useMyPathname();

  const statusKey = _.chain(pathname).split('/', 4).last().value();

  const statusOptions = getStatusOpts(counts.data);

  const getStatusToProps = useCallback<ISportFilter['getStatusToProps']>(
    (status) => {
      if (!currentSport) return '#';
      return {
        pathname: generatePath(Paths.MetaSport, {
          sportType: currentSport.__sportType,
          slug: currentSport.slug,
          eventId: status,
        }),
        ...(status !== 'futures' && { query: queries }),
      };
    },
    [currentSport, queries],
  );

  const getFilterToProps = useCallback<ISportFilter['getFilterToProps']>(
    (market) => {
      return {
        query: { ...queries, [QUERY_FILTER_KEY]: market },
      };
    },
    [queries],
  );

  const selectedStatus = statusOptions
    .find({ key: statusKey })
    .get('val', statusOptions.first().get('val', undefined).value())
    .value();

  const { locale } = useLocale();

  const markets = useSWRImmutable(
    currentSport
      ? fetchPopularMarketsKey({
          sportSlug: currentSport.slug,
          lang: locale,
        })
      : null,
    ([key, params]) => {
      return fetchPopularMarkets(params);
    },
  );

  const selectedMarket =
    _.find(markets.data, { marketCustomName: marketName }) ??
    _.first(markets.data);

  const props = {
    currentSport,
    selectedMarket,
    selectedStatus,
    marketOptions: markets.data,
    statusOptions: statusOptions.value(),
    getStatusToProps,
    getFilterToProps,
    isLoading: counts.isLoading || markets.isLoading,
  } satisfies ISportFilter;

  return (
    <SportFilterCtx.Provider value={props}>{children}</SportFilterCtx.Provider>
  );
};
