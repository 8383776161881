import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    container: {
      padding: theme.spacing(2),
      [mobile]: { display: 'none' },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    more: {
      position: 'relative',
    },

    icon: {
      top: 'calc(50% + 5px)',
      right: -5,
      position: 'absolute',
      transition: theme.transitions.create(['transform']),
    },

    rotate: {
      transform: 'rotate(180deg)',
    },
    header: {
      cursor: 'pointer',
      '& > *': {
        paddingTop: 19,
        paddingBottom: 19,
      },
    },
  };
});
