import { Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useSportFilter } from 'src/hooks/use-sport-filter';
import { BaseEvent } from 'src/utils/types/event';
import { useStyles } from './styles';

export const MarketName: React.FCC<{ data?: BaseEvent }> = ({ data }) => {
  const { classes, cx } = useStyles();
  const filter = useSportFilter();

  const marketName = _.chain(data?.mainPopularOutcomes)
    .get('marketOutcomes')
    .first()
    .get(
      'marketName',
      filter?.selectedMarket?.marketCustomName ??
        filter?.selectedMarket?.marketName,
    )
    .value();

  if (!marketName) return null;

  return (
    <Typography
      className={classes.marketName}
      align="center"
      color="custom.content.2">
      {marketName}
    </Typography>
  );
};
