import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import ArrowDown from 'src/assets/icons/arrow-down.svg';
import { BaseEvent } from 'src/utils/types/event';
import { MarketName } from '../market-name';
import { useStyles } from './styles';

interface FixtureHeadV2Props {
  data?: BaseEvent[];
  open?: boolean;
  onClick: () => void;
}

// this component should have a <Grid> structure like FixtureRow
export const FixtureHeadV2: React.FCC<FixtureHeadV2Props> = ({
  data,
  open,
  onClick,
}) => {
  const { classes, cx } = useStyles();

  const first = _.first(data);

  return (
    <div className={classes.container} onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item md={5.6} />
        <Grid
          item
          md={5.2}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent="center">
          <MarketName data={first} />
        </Grid>
        <Grid item md={1.2} className={classes.more}>
          <Typography align="center">
            <Trans id="common.more">More</Trans>
          </Typography>

          <ArrowDown className={cx(classes.icon, open && classes.rotate)} />
        </Grid>
      </Grid>
    </div>
  );
};
