import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    container: {
      boxShadow: '0px 4px 4px 0px #00000029',
    },

    loadMore: {
      textAlign: 'center',
      padding: theme.spacing(2.5),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      '& > span': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' },
      },
    },
    loading: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2.5),
      textAlign: 'center',
      color: theme.custom.content[2],
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  };
});
