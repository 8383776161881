import { Link as MuiLink } from '@mui/material';
import moment from 'moment';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { toStartCase } from 'src/utils/helpers/fixture';
import { makeStyles } from 'tss-react/mui';
import { FixtureInfoProps } from '..';
import { convertTimeBetISO } from 'src/utils/helpers/time';

const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingRight: theme.spacing(2),
      [mobile]: {
        paddingRight: 0,
        marginBottom: 10,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    gutterBottom: {
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
    },
    colorWhite: {
      color: theme.palette.common.white,
    },
    date: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.custom.content[2],
      textAlign: 'right',
    },
  };
});

export const Normal: React.FCC<FixtureInfoProps> = ({ data, href }) => {
  const { classes, cx } = useStyles();

  const { startTime, competitor1: home, competitor2: away } = data;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <MuiLink
          component="div"
          className={cx(classes.gutterBottom, 'desktop')}>
          {toStartCase(home)}
        </MuiLink>
        <MuiLink component="div" className={cx(classes.colorWhite, 'desktop')}>
          {toStartCase(away)}
        </MuiLink>
        <MuiLink component="div" className={cx(classes.colorWhite, 'mobile')}>
          {`${toStartCase(home)} - ${toStartCase(away)}`}
        </MuiLink>
      </div>

      <div className={classes.date}>
        <span className={'desktop'}>{convertTimeBetISO(startTime)}</span>
        <span className={'mobile'}>{convertTimeBetISO(startTime)}</span>
      </div>
    </div>
  );
};
