/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, Grid, IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import CloseIcon from 'src/assets/icons/close.svg';
import { AspectRatio } from 'src/components/core/aspect-ratio';
import { getStreamURL } from 'src/utils/helpers/etc';
import { BaseEvent } from 'src/utils/types/event';
import { useStyles } from './styles';
interface StreamFrameV2Props {
  data: BaseEvent;
  open?: boolean;
  onClose?: (e: any) => any;
}
export const StreamFrameV2: React.FCC<StreamFrameV2Props> = ({
  data,
  open,
  onClose,
}) => {
  const streamURL = useMemo(() => {
    const tvChannelsData = _.flatten([data.tvChannels?.tv_channel ?? []]);
    return getStreamURL(tvChannelsData);
  }, [data.tvChannels?.tv_channel]);

  const { classes, cx } = useStyles();
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open) return;

    rootRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [open]);

  if (!streamURL) return null;

  return (
    <Collapse
      in={open}
      component={Grid}
      // @ts-ignore
      item
      md={12}
      sm={12}
      xs={12}
      mountOnEnter
      unmountOnExit>
      <div className={classes.root}>
        <div ref={rootRef} className={classes.title}>
          <span>{`${data?.competitor1} - ${data?.competitor2}`}</span>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon width={12} height={12} />
          </IconButton>
        </div>
        <AspectRatio>
          <iframe
            className={classes.iframe}
            src={streamURL}
            allowFullScreen
            title="Live Streaming"
            width="100%"
            height="100%"
          />
        </AspectRatio>
      </div>
    </Collapse>
  );
};
