import { Trans } from '@lingui/macro';
import { Divider, Link as MuiLink, Typography } from '@mui/material';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { useMatchStatus } from 'src/hooks/use-match-status';
import { isWatchableLive, toStartCase } from 'src/utils/helpers/fixture';
import { displayTime } from 'src/utils/helpers/sport';
import { SportTypeEnum } from 'src/utils/types/sport';
import { makeStyles } from 'tss-react/mui';
import { FixtureInfoProps } from '..';
import { MatchScore } from '../match-score';
import { convertTime } from 'src/utils/helpers/time';

const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      [mobile]: {
        alignItems: 'stretch',
        paddingRight: 0,
        flexDirection: 'column',
      },

      '& .green': { color: theme.custom.status.green },
    },

    gutterBottom: {
      marginBottom: theme.spacing(1),
      [mobile]: { marginBottom: 0 },
    },

    row1: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    row2: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 50%',
      marginRight: theme.spacing(2),
      maxWidth: '50%',
      [mobile]: {
        width: '100%',
        margin: '15px 0px 10px',
        maxWidth: 'unset',
      },

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },

    player: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '60%',
      color: theme.palette.common.white,
    },
    divider: { marginRight: 16 },

    row3: {
      display: 'flex',
      flexDirection: 'column',

      '& > *:not(:last-child)': { marginBottom: theme.spacing(0.5) },
    },
  };
});

export const Live: React.FCC<FixtureInfoProps> = (props) => {
  const { data } = props;
  const { classes, cx } = useStyles();

  const badge = <LiveBadge {...props} />;

  return (
    <div className={classes.root}>
      <div className={cx(classes.row1, 'mobile')}>
        {badge}
        <span className="green">{convertTime(data.startTime)}</span>
      </div>

      <div className={classes.row2}>
        <div>
          <MuiLink
            component="div"
            className={cx(classes.player, classes.gutterBottom)}>
            {toStartCase(data.competitor1)}
          </MuiLink>
          <MatchScore team="a" {...props} />
        </div>
        <div>
          <MuiLink component="div" className={classes.player}>
            {toStartCase(data.competitor2)}
          </MuiLink>
          <MatchScore team="b" {...props} />
        </div>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        className={cx(classes.divider, 'desktop')}
      />

      <div className={cx(classes.row3, 'desktop')}>
        {badge}

        <span className="green">{convertTime(data.startTime)}</span>
      </div>
    </div>
  );
};

const useBadgeStyles = makeStyles()((theme) => ({
  badgeWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  badge: {
    backgroundColor: theme.custom.status.green,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    padding: '3px 5px',
    borderRadius: 3,
    color: theme.custom.content[1],
    marginRight: theme.spacing(1),
    textTransform: 'uppercase',
  },
  watchable: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    padding: '8px 4px',
    margin: '-8px -4px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const LiveBadge: React.FCC<FixtureInfoProps> = ({ data, onWatchNow }) => {
  const { classes } = useBadgeStyles();
  const desc = useMatchStatus(data.sportEventStatus);
  const isLiveMatchEsport = data && isWatchableLive(data);
  const sport = useSportFinder({ sportId: data.sportId });

  const clock = data.sportEventStatus?.clock || data.sportEventStatus;

  return (
    <div className={classes.badgeWrapper}>
      <span className={classes.badge}>
        <Trans id="sportsbook.live">Live</Trans>
      </span>
      {sport?.__sportType === SportTypeEnum.Sport ? (
        <Typography color="custom.content.2">
          {desc}
          {displayTime(data.sportId, clock)}
        </Typography>
      ) : isLiveMatchEsport ? (
        <span onClick={onWatchNow} className={classes.watchable}>
          <Trans id="sportsbook.live.watch_now">Watch Now</Trans>
        </span>
      ) : null}
    </div>
  );
};
