import FavIcon from 'src/assets/icons/icon-fav-sport.svg?url';
import UnFavIcon from 'src/assets/icons/icon-unfav-sport.svg?url';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const activeColor = theme.palette.common.white;
  const inactiveColor = theme.palette.common.white;

  return {
    fav: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1),
      flexShrink: 0,
      background: 'transparent',
      //https://vscode.dev/github/Saintly-global/ubet-frontend/blob/develop/src/components/modules/sportsbook-v2/season/styles.ts#L12
      pointerEvents: 'auto',
    },

    active: {
      cursor: 'pointer',
      WebkitMask: `url(${FavIcon.src}) no-repeat center`,
      mask: `url(${FavIcon.src}) no-repeat center`,
      '@media(hover: hover)': {
        '&:hover': {
          WebkitMask: `url(${UnFavIcon.src}) no-repeat center`,
          mask: `url(${UnFavIcon.src}) no-repeat center`,
        },
      },
    },
    inactive: {
      cursor: 'pointer',
      WebkitMask: `url(${UnFavIcon.src}) no-repeat center`,
      mask: `url(${UnFavIcon.src}) no-repeat center`,
      '@media(hover: hover)': {
        '&:hover': {
          WebkitMask: `url(${FavIcon.src}) no-repeat center`,
          mask: `url(${FavIcon.src}) no-repeat center`,
        },
      },
    },
    isFav: {
      backgroundColor: activeColor,
      '@media(hover: hover)': {
        '&:hover': {
          backgroundColor: inactiveColor,
        },
      },
    },
    isNotFav: {
      backgroundColor: inactiveColor,
      '@media(hover: hover)': {
        '&:hover': {
          backgroundColor: activeColor,
        },
      },
    },
  };
});
