import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { matchStatusesAtom } from 'src/store/settings';
import { SportEvent } from 'src/utils/types/event';

export const useMatchStatus = (_event?: SportEvent.Root, enable = true) => {
  const matchStatuses = useAtomValue(matchStatusesAtom);

  if (!enable) return;

  const code =
    _event?._match_status ??
    _.last(_event?.period_scores?.period_score)?._match_status_code;

  return _.find(matchStatuses, { code })?.description;
};
