import React from 'react';
import { MyLink } from 'src/components/core/mine/my-link';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { Paths } from 'src/utils/constants/path';
import { isLive } from 'src/utils/helpers/fixture';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { BaseEvent } from 'src/utils/types/event';
import { Live } from './live';
import { Normal } from './normal';

export interface FixtureInfoProps {
  data: BaseEvent;
  onWatchNow?: (e: any) => any;
  href: string;
}

export const FixtureInfo: React.FCC<Omit<FixtureInfoProps, 'href'>> = (
  props,
) => {
  const { data } = props;

  const sport = useSportFinder({ sportId: data.sportId });

  const redirect = sport
    ? generatePath(Paths.DetailFixture, {
        sportType: sport.sportId,
        slug: sport.slug,
        eventId: data._eventSlug ?? data.eventId,
      })
    : '#';

  return (
    <MyLink
      href={redirect}
      underline="none"
      display="block"
      color="inherit"
      sx={{
        p: 2,
        m: -2,
        zIndex: 10,
        position: 'relative',
      }}>
      {React.createElement(isLive(data) ? Live : Normal, {
        ...props,
        href: redirect,
      })}
    </MyLink>
  );
};
