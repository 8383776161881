import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    marketName: {
      width: '100%',
      [mobile]: {
        color: theme.custom.content[2],
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingLeft: 0,
      },
    },
  };
});
