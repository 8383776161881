import _ from 'lodash';
import { getBRLocale } from 'src/utils/helpers/i18n';
import { request } from 'src/utils/request';
import { PaginationResponse } from 'src/utils/types/response';
import { IMarketFilter } from 'src/utils/types/sport';

export const fetchPopularMarkets = async (params: {
  sportSlug?: string;
  sportId?: string;
  lang?: string;
}) => {
  const { lang, sportId, sportSlug } = params;

  const locale = getBRLocale(lang);
  const { data } = await request.get<PaginationResponse<IMarketFilter>>(
    `/fixture-popular-market/popular-market-by-sport-slug`,
    { params: { slug: sportSlug, sportId: sportId, lang: locale } },
  );

  const result = _.map(data.data.items, (o) =>
    formatDataFetchPopularMarkets(o, locale),
  );

  return result;
};

const formatDataFetchPopularMarkets = (
  data: IMarketFilter,
  lang: string | undefined,
) => {
  const { languages } = data;

  if (languages === undefined || lang === undefined) return data;

  const result: IMarketFilter = {
    ..._.omit(data, 'languages'),
    marketCustomName: _.get(
      languages,
      [lang, 'marketPopularName'],
      data.marketCustomName,
    ),
  };

  return result;
};
