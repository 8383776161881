import { Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import ArrowDown from 'src/assets/icons/arrow-down.svg';
import { FavoriteButton } from 'src/components/core/favorite-button';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { useIsFavTour } from 'src/hooks/use-is-fav-league';
import { useSBLayout } from 'src/hooks/use-sb-layout';
import { toggleFavTourAtom } from 'src/store/sport';
import { ITournament } from 'src/utils/types/event';
import { useStyles } from './styles';
export interface SeasonItemV2Props {
  index?: number;
  open?: boolean;
  onChange?: (next: boolean) => any;
  data: ITournament;
  className?: string;
}

/** TODO: Remove me */
const FALLBACK_IAMGE =
  'https://prod-ubet-fantasy-logo.s3.eu-central-1.amazonaws.com/stg-1684474270027.svg';

export const SeasonItemV2: React.FCC<SeasonItemV2Props> = ({
  data,
  index,
  open: _open,
  onChange: _onChange,
  children,
  className,
}) => {
  const { classes, cx } = useStyles();

  const { tournamentId, name, _id } = data;

  const currentSport = useSportFinder({ sportId: data.sportId });

  const fav = useIsFavTour(tournamentId);
  const toggleFavLeague = useSetAtom(toggleFavTourAtom);

  // ========= SIDE EFFECT =========
  const { season, toggleSeason } = useSBLayout(index);

  const open = _open ?? season;

  const toggleOpen = () => {
    const next = !(open ?? false);

    if (_onChange) {
      return _onChange(next);
    }
    toggleSeason(next);
  };

  return (
    <div className={className}>
      <div className={classes.title} onClick={toggleOpen}>
        <img
          width={16}
          height={16}
          src={currentSport?.logo || FALLBACK_IAMGE}
          alt="logo"
        />
        <Typography variant="body2">{name}</Typography>
        <FavoriteButton
          favorite={fav}
          onClick={(e) => {
            toggleFavLeague(_id);
          }}
        />
        <div className="grow" />
        <ArrowDown className={cx(classes.arrow, open && 'rotate')} />
      </div>

      {children}
    </div>
  );
};
