import { useAtomValue } from 'jotai';
import { MouseEventHandler } from 'react';
import { loggedAtom } from 'src/store/auth';
import { useStyles } from './styles';

interface Props {
  favorite: boolean;
  onClick?: MouseEventHandler;
  className?: string;
  favClassName?: string;
  notFavClassName?: string;
}

export const FavoriteButton: React.FCC<Props> = ({
  className,
  favorite,
  onClick,
  favClassName,
  notFavClassName,
}) => {
  const { classes, cx } = useStyles();
  const logged = useAtomValue(loggedAtom);

  return (
    <div
      onClick={
        logged
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.(e);
            }
          : undefined
      }
      className={cx(
        classes.fav,

        logged &&
          (favorite
            ? [favClassName ?? classes.isFav, classes.active]
            : [notFavClassName ?? classes.isNotFav, classes.inactive]),
        className,
      )}
    />
  );
};
