import { Trans } from '@lingui/macro';
import { Collapse, LinearProgress, Paper, PaperProps } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useSBLayout } from 'src/hooks/use-sb-layout';
import { BaseEvent } from 'src/utils/types/event';
import { SomeThingGoneWrong } from '../shared/error';
import { FixtureHeadV2 } from './components/fixture-head';
import { FixtureRowV2 } from './components/fixture-row';
import { useStyles } from './styles';

interface FixtureV2Props extends PaperProps {
  data?: BaseEvent[];
  // totalCount?: number;
  loading?: boolean;
  onLoadMore?: () => any;
  className?: string;
  index?: number;
  hasMore?: boolean;
}
export const FixtureTableV2 = React.forwardRef<HTMLDivElement, FixtureV2Props>(
  (props, ref) => {
    const { data, loading, className, index, hasMore, onLoadMore, ...rest } =
      props;
    const { season, toggleSeason } = useSBLayout(index);

    const toggleOpen = () => {
      const next = !(season ?? false);
      toggleSeason(next);
    };

    // let hasMore = false;

    // if (!_.isNil(data?.length) && totalCount > 0) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   hasMore = data!.length < totalCount;
    // }
    const { classes, cx } = useStyles();

    const isEmpty = !loading && _.isEmpty(data);

    return (
      <Paper {...rest} ref={ref} className={cx(classes.container, className)}>
        <FixtureHeadV2 data={data} open={season} onClick={toggleOpen} />
        <Collapse in={season}>
          {isEmpty ? (
            <SomeThingGoneWrong>
              <Trans id="sportsbook.empty_match">
                New games will be available in the future.
              </Trans>
            </SomeThingGoneWrong>
          ) : (
            data?.map((item) => {
              return <FixtureRowV2 data={item} key={item.eventId} />;
            })
          )}

          {loading ? (
            <Paper className={classes.loading}>
              <span>
                <Trans id="common.loading">Loading...</Trans>
              </span>
              <LinearProgress className={classes.progress} />
            </Paper>
          ) : (
            !isEmpty &&
            hasMore && (
              <Paper className={classes.loadMore}>
                <span onClick={onLoadMore}>
                  <Trans id="common.load_more">Load More</Trans>
                </span>
              </Paper>
            )
          )}
        </Collapse>
      </Paper>
    );
  },
);

FixtureTableV2.displayName = 'FixtureTableV2';
